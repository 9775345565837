/**
 * removes all accents
 */
export function accentCleanse(input: string, strict?: boolean): string {
	if (strict === true) return input
	return input
		.normalize('NFD') // Normalize the string to its decomposed form
		.replace(/[\u0300-\u036f]/g, '') // Remove diacritical marks
		.replace(/ø/g, 'o')
		.replace(/æ/g, 'a')
		.replace(/œ/g, 'o')
		.replace(/ß/g, 'ss')
}

/**
 * Geht alle Wörter durch und entscheidet sich für ein Wort
 * bei Wörtern mit doppelter Übersetzung
 */
export function sanitizeWords(words: string[]) {
	return words.map((word) => {
		if (word.includes(',')) {
			const wordArray = word.split(',')
			return wordArray[Math.floor(Math.random() * wordArray.length)].trim()
		} else {
			return word.replace(' ', '')
		}
	})
}