import Words from './Words'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { useEffect, useState } from 'react'
import Timer from './Timer'
import { LanguageInfo, Result } from '@src/types'
import ModiBar from './ModiBar'
import Sentences from './Sentences'
import { useAppSelector, useAppDispatch } from '@src/redux/hooks'
import { resetGame, selectGame } from './gameSlice'
import axios from 'axios'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import HelpModal from './HelpModal'
import { accentCleanse } from '@src/util'

const languages: LanguageInfo[] = [
	{ code: 'en', name: 'English' },
	{ code: 'de', name: 'German' },
	{ code: 'fr', name: 'French' },
	{ code: 'es', name: 'Spanish' },
	{ code: 'it', name: 'Italian' },
	{ code: 'nl', name: 'Dutch' },
	{ code: 'nb', name: 'Norwegian' },
	{ code: 'fi', name: 'Finnish' },
	{ code: 'tr', name: 'Turkish' },
	{ code: 'ja', name: 'Japanese' },
	{ code: 'ko', name: 'Korean' },
]

export default function Homepage() {
	//wird als key für die Words Komponente genutzt um React zu einem Reload zu bringen
	const [seed, setSeed] = useState<number>(1)

	const [showHelp, setShowHelp] = useState<boolean>(false)

	const [nativeLanguage, setNativeLanguage] = useState('de') // default values
	const [foreignLanguage, setForeignLanguage] = useState('es')

	const token = localStorage.getItem('token')

	const gameSlice = useAppSelector(selectGame)

	const dispatch = useAppDispatch()

	const [result, setResult] = useState<Result | undefined>()

	//erzwingt einen Reload der Words und Timer Komponente
	function handleReload() {
		setSeed(Math.random())
		dispatch(resetGame())
	}

	function calculateWPM(characters: number, timeInSeconds: number): number {
		const timeInMinutes = timeInSeconds / 60
		const wpm = characters / 5 / timeInMinutes

		return wpm
	}

	useEffect(() => {
		handleReload()
	}, [gameSlice.settings])

	useEffect(() => {
		if (!gameSlice.gameOver) return

		let inputWords: string[] = gameSlice.combinedWords
			.map((combined) => combined.input)
			.filter((input) => input !== undefined)
			.slice(0, -1) as string[]

		const inputWords2: string[] = gameSlice.combinedWords.map((combined) => combined.input).filter((input) => input !== undefined) as string[]

		let foreignWords: string[] = gameSlice.combinedWords.map((combined) => combined.foreign)

		if (!gameSlice.settings.strict) {
			inputWords = inputWords.map((word) => accentCleanse(word))
			foreignWords = foreignWords.map((word) => accentCleanse(word))
		}

		const correctWords = inputWords.reduce((acc, current) => (foreignWords.includes(current) ? acc + 1 : acc), 0)
		const charAmount = inputWords.filter((word) => foreignWords.includes(word)).join(' ').length

		if (gameSlice.settings.mode === 'sentences') return

		const userId: number = token ? JSON.parse(atob(token.split('.')[1])).sub : undefined

		const time = gameSlice.settings.mode === 'time' ? gameSlice.settings.value : gameSlice.settings.ellapsed

		setResult({
			words_amount: inputWords.length,
			// words_amount: correctWords,
			char_amount: charAmount,
			time: gameSlice.settings.mode === 'time' ? gameSlice.settings.value : gameSlice.settings.ellapsed,
			wpm: calculateWPM(time ?? 1, correctWords),
			accuracy: correctWords / inputWords.length,
			language: foreignLanguage,
			// mode: gameSlice.settings.mode,
			mode: gameSlice.settings.mode === 'time' ? gameSlice.settings.value + 's' : gameSlice.settings.value + 'w',
			user_id: userId,
		})
	}, [gameSlice.gameOver])

	useEffect(() => {
		if (!result || !token) return
		// console.log(result)

		const filteredResult = { ...result }
		delete filteredResult.wpm

		axios
			// .post(import.meta.env.VITE_API_URL + 'results/', result, { // hack to pass wpm around
			.post(import.meta.env.VITE_API_URL + 'results/', filteredResult, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			// .then((response) => {
			// 	// console.log(response)
			// })
			.catch((error) => {
				console.log(error)
			})
	}, [result])

	return (
		<div className="flex h-[calc(100vh-68px)] flex-col items-center justify-center gap-10">
			{gameSlice.gameOver ? (
				<div className="flex flex-col gap-4 text-2xl" style={{ color: '#b8b8b8' }}>
					<div>Words: {result?.words_amount}</div>
					<div>Characters: {result?.char_amount}</div>
					<div>Time: {result?.time}</div>
					<div>WPM: {result?.words_amount ?? 0 / (result?.time ?? 0)}</div>
					<div>Accuracy: {((result?.accuracy ?? 0) * 100).toString().slice(0, 5) + '%'}</div>
				</div>
			) : (
				<>
					<div className="flex items-center">
						<div className="dropdown">
							<div tabIndex={0} role="button" style={{ color: '#E5E7EB', backgroundColor: '#2D3748' }} className="btn btn-sm m-1 text-gray-200">

								Native language
							</div>
							<ul tabIndex={0} className="menu dropdown-content z-[1] w-52 rounded-box" style={{ backgroundColor: '#2D3748', padding: '0.5rem', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', color: '#E5E7EB' }}>
								{languages
									.filter((language) => language.code !== foreignLanguage)
									.map((language) => (
										<li
											key={language.code}
											value={language.code}
											onClick={() => {
												setNativeLanguage(language.code)
												handleReload()
											}}
										>
											<a>{language.name}</a>
										</li>
									))}
							</ul>
						</div>

						<div className="text-gray-200">
							<ArrowRightAltIcon />
						</div>

						<div className="dropdown">
							<div tabIndex={0} role="button" style={{ color: '#E5E7EB', backgroundColor: '#2D3748' }} className="btn btn-sm m-1 text-gray-200">
								Foreign language
							</div>
							<ul tabIndex={0} className="menu dropdown-content z-[1] w-52 rounded-box" style={{ backgroundColor: '#2D3748', padding: '0.5rem', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', color: '#E5E7EB' }}>
								{languages
									.filter((language) => language.code !== nativeLanguage)
									.map((language) => (
										<li
											key={language.code}
											value={language.code}
											onClick={() => {
												setForeignLanguage(language.code)
												handleReload()
											}}
										>
											<a>{language.name}</a>
										</li>
									))}
							</ul>
						</div>
					</div>

					<div className="flex flex-col items-end gap-y-0">
						<div className="right-0 rounded-t-3xl border-0 bg-gray-800">
							<ModiBar />
						</div>
						{gameSlice.settings.mode === 'sentences' ? <div></div> : <Words nativeLanguage={nativeLanguage} foreignLanguage={foreignLanguage} key={seed + 1} />}
					</div>
				</>
			)}
			<div className="text-xl font-semibold">{gameSlice.settings.mode === 'time' ? <Timer key={seed} /> : null}</div>
			<div>
				<button onClick={handleReload} title="Click to restart">
					<RestartAltIcon sx={{ color: 'white' }} fontSize="large" />
				</button>
			</div>
			<div className="absolute bottom-5 right-5">
				<button onClick={() => setShowHelp(true)}>
					<HelpOutlineIcon sx={{ color: 'white' }} fontSize="large" />
				</button>
			</div>
			{showHelp ? (
				<div className="absolute">
					<HelpModal isVisible={setShowHelp} />
				</div>
			) : null}
		</div>
	)
}
