import React, { useState, useEffect } from 'react'
import fetchWithToken from './login/fetchWithToken'

type GlobalStats = {
	total_runs: number
	total_words: number
	total_time: number
	highScore: number
}

type GlobalStatsComponentProps = {
	friendId: number
	token: string
}

const GlobalStatsComponent: React.FC<GlobalStatsComponentProps> = ({ friendId, token }) => {
	const [globalStats, setGlobalStats] = useState<GlobalStats | null>(null)
	const [isLoading, setIsLoading] = useState(true)
	const [error, setError] = useState('')

	useEffect(() => {
		const fetchGlobalStats = async () => {
			setIsLoading(true)
			try {
				const response = await fetchWithToken(import.meta.env.VITE_API_URL + `friends/globalStats/${friendId}`, {
					headers: { Authorization: `Bearer ${token}` },
				})

				if (!response.ok) {
					throw new Error('Failed to fetch global stats')
				}

				const data = await response.json()
				setGlobalStats(data)
			} catch (error: any) {
				setError('Error fetching global stats: ' + error.message)
				console.error('Error fetching global stats:', error)
			} finally {
				setIsLoading(false)
			}
		}

		fetchGlobalStats()
	}, [friendId, token])

	if (isLoading) {
		return <div>Loading global stats...</div>
	}

	if (error) {
		return <div>{error}</div>
	}

	return (
		<div className="mt-4 flex w-full justify-around">
			<div className="text-center">
				<p className="text-sm font-semibold">Total Runs</p>
				<p className="text-lg">{globalStats?.total_runs}</p>
			</div>
			<div className="text-center">
				<p className="text-sm font-semibold">Total Words</p>
				<p className="text-lg">{globalStats?.total_words}</p>
			</div>
			<div className="text-center">
				<p className="text-sm font-semibold">Total Time</p>
				<p className="text-lg">{globalStats?.total_time}</p>
			</div>
			<div className="text-center">
				<p className="text-sm font-semibold">High Score</p>
				<p className="text-lg">{globalStats?.highScore}</p>
			</div>
		</div>
	)
}

export default GlobalStatsComponent
