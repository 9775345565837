// Erstelle eine separate Komponente für die Registrierungsseite (RegistrationPage.tsx)
import React from 'react'
import RegistrationForm from './RegisterForm'
import '@styles/registrationPage.css'

const RegistrationPage: React.FC = () => {
	return (
		<div className="flex h-[calc(100vh-68px)] w-screen flex-col items-center justify-center">
			<h1 className="-mt-28 mb-9 text-5xl text-white">Create an account!</h1>
			<div className="flex w-64 flex-col text-center">
				<RegistrationForm />
				<a className="text-gray-400 hover:text-gray-100" href="/login">
					Already have an account?
				</a>
			</div>
		</div>
	)
}

export default RegistrationPage
