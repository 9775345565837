// refreshAccessToken.ts
async function refreshAccessToken(): Promise<boolean> {
    try {
        console.log('Sending request to refresh token');
        const response = await fetch(import.meta.env.VITE_API_URL + 'auth/refresh-token', {
            method: 'POST',
            credentials: 'include',
        });
        // console.log('Response received', response);
        if (!response.ok) {
            console.error('Response not ok', response);
            throw new Error('Token refresh failed');
        }
        const data = await response.json();
        // console.log('Response data:', data);
        if (data && data.access_token) {
            localStorage.setItem('token', data.access_token);
            // console.log('Token successfully refreshed', data.access_token);
        } else {
            console.error('Token missing in response data', data);
            return false;
        }
        return true;
    } catch (error) {
        console.error('Refresh token failed', error);
        return false;
    }
}
export default refreshAccessToken;
