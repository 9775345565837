import '@styles/passwordChangedPage.css'

// ResetPassword.tsx
import React, { useState, useEffect } from 'react'

const ResetPassword: React.FC = () => {
	const [newPassword, setNewPassword] = useState('')

	useEffect(() => {
		// Extrahiere Token aus dem lokalen Speicher
		const url = new URL(window.location.href)
		const token = url.searchParams.get('token')
		if (token) {
			localStorage.setItem('token', token)
			console.log('Token erfolgreich im Local Storage gespeichert:', token)
		} else {
			alert('no token gefunden')
		}
	}, [])

	const handleResetPassword = async () => {
		try {
			//token aus localstorage holen
			const storedToken = localStorage.getItem('token')
			if (!storedToken) {
				console.error('token not found')
				return
			}
			const response = await fetch(import.meta.env.VITE_API_URL + 'auth/reset-password', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ token: storedToken, newPassword }),
			})
			if (response.ok) {
				console.log('Passwort erfolgreich zurückgesetzt.')
				alert('Passwort erfolgreich zurückgesetzt.')
				localStorage.removeItem('token')
				setTimeout(() => {
					window.location.href = '/login'
				}, 2000)
			} else {
				alert('Fehler beim passwort zurücksetzen')
				console.error('Fehler beim Zurücksetzen des Passworts.')
			}
		} catch (error) {
			alert('Fehler beim Zurücksetzen des Passworts:' + error)
			console.error('Fehler beim Zurücksetzen des Passworts:', error)
		}
	}

	return (
		<div className="flex h-[calc(100vh-68px)] w-screen flex-col items-center justify-center">
			<h1 className="-mt-28 mb-9 text-5xl text-white">Reset password!</h1>
			<div className="flex flex-col">
				<input
					className="sm:text-md mb-5 block rounded-lg border border-gray-900 bg-gray-800 p-4 text-white"
					type="password"
					placeholder="Password"
					value={newPassword}
					onChange={(e) => setNewPassword(e.target.value)}
				/>
				<button className="mb-5 rounded-lg border-gray-900 bg-gray-900 p-4 text-white hover:text-gray-500" onClick={handleResetPassword}>
					Reset password
				</button>
			</div>
		</div>
	)
}

export default ResetPassword
