import { useAppDispatch, useAppSelector } from '@src/redux/hooks'
import { useEffect, useState } from 'react'
import { finishGame, selectGame } from './gameSlice'
import useTimer from 'easytimer-react-hook'

//Timer braucht GameEnded Prop um das an Homepage zu übergeben

// function Timer(props: { time: number }) {
// 	const [seconds, setSeconds] = useState(props.time)
// 	const [letterColor, setLetterColor] = useState('text-gray-200')
// 	// const [timerStarted, setTimerStarted] = useState(false)

// 	const gameSlice = useAppSelector(selectGame)
// 	const dispatch = useAppDispatch()

// 	// //wenn keydown wird der timer gestartet
// 	// const handleKeyPress = () => {
// 	// 	setTimerStarted(true)
// 	// }

// 	// //wartet auf keyboard input
// 	// window.addEventListener('keydown', handleKeyPress)

// 	// useEffect(() => {
// 	// 	if (gameSlice.inputWords.length > 0) dispatch(startGame())
// 	// 	console.log(gameSlice.inputWords.length)
// 	// }, [gameSlice.inputWords])

// 	//Zählt runter von time zu 0, bis 0 erreicht ist
// 	useEffect(() => {
// 		if (gameSlice.gameRunning) {
// 			const intervalId = setInterval(() => {
// 				// TODO: use easytimer.js instead (maybe, for pausing)
// 				setSeconds((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0))
// 			}, 1000)

// 			return () => clearInterval(intervalId)
// 		}
// 	}, [gameSlice.gameRunning])

// 	//wenn 0 erreicht ist wird der User auf die Statistik-Seite weitergeleitet
// 	useEffect(() => {
// 		if (seconds === 10) {
// 			setLetterColor('text-red-400')
// 		}
// 		if (seconds <= 0) {
// 			dispatch(finishGame())
// 		}
// 	}, [seconds, dispatch])

// 	return <div className={letterColor}>{seconds}</div>
// }

function Timer() {
	const gameSlice = useAppSelector(selectGame)
	const dispatch = useAppDispatch()

	const [timer] = useTimer({
		startValues: { seconds: gameSlice.settings.value || 15 },
		countdown: true,
	})

	useEffect(() => {
		if (gameSlice.gameRunning) {
			timer.start()
			timer.addEventListener('secondsUpdated', () => {
				if (timer.getTimeValues().seconds <= 0) dispatch(finishGame())
			})
		} else {
			timer.pause()
		}
	}, [gameSlice.gameRunning])

	function getSeconds() {
		const time = timer.getTimeValues()
		return time.seconds + time.minutes * 60
	}

	return <div style={{ color: '#E5E7EB' }}>{getSeconds()}</div>
	// return ( // daisyUI countdown but slightly off sync
	// 	<span className="countdown">
	// 		<span style={{ '--value': Math.max(getSeconds() - 1, 0) } as React.CSSProperties}></span>
	// 	</span>
	// )
}

export default Timer
