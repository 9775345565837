import LogoutIcon from '@mui/icons-material/Logout';

function Logout() {

    function handleLogout() {
        localStorage.removeItem('token')
        localStorage.removeItem('profilePictureUrl')
        window.location.reload()
    }

    return (
        <a className="logoutButton" onClick={handleLogout} style={{ color: '#E5E7EB' }}>
            Logout
        </a>
    )
}

export default Logout;