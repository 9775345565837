import axios from 'axios'
import { get } from 'http'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { LeaderboardEntry, Result } from '@src/types'
import { Card, Typography } from '@material-tailwind/react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

function LeaderboardPage() {
	// const [leaderboard, setLeaderboard] = useState<string[][]>([[], [], [], [], [], [], []]) //wpm, accuracy, language, mode, time, words, user
	const [leaderboard, setLeaderboard] = useState<LeaderboardEntry[]>([])
	const [resultResponse, setResultResponse] = useState<Result[]>([])

	const TABLE_HEAD = ['#', 'User', 'Words', 'Accuracy', 'Language', 'Mode', 'Time', 'WPM']

	//gets all Results from the server
	async function fetchResults() {
		try {
			const leaderboardResponse = await axios.get(import.meta.env.VITE_API_URL + 'results')
			// console.log(leaderboardResponse.data)
			if (leaderboardResponse.status === 200) {
				setResultResponse(leaderboardResponse.data) //Results get put in array of Result-Objects
			} else {
				return toast.error('could not load leaderboard from server')
			}
		} catch (error) {
			console.error('Fehler beim Laden der Leaderboard', error)
			return toast.error('could not load leaderboard from server')
		}
	}

	function getWPM(words_amount: number, time: number) {
		return Math.round(words_amount / (time / 60)).toString()
	}

	function formatAccuracy(accuracy: number) {
		return Math.round(accuracy * 100) + '%'
	}

	//gets the username of the user with the given id
	async function getUser(userId: number) {
		try {
			const userResponse = await axios.get(import.meta.env.VITE_API_URL + 'user/' + userId)
			// console.log(userResponse)
			return userResponse.data.username
		} catch (error) {
			console.error('Fehler beim Laden des Benutzers', error)
			return toast.error('could not load user from server')
		}
	}

	async function getProfilePicture(userId: number) {
		const test = userId // to remove unused var warning
		// try {
		// 	const userResponse = await axios.get(import.meta.env.VITE_API_URL + 'user/' + userId)
		// 	console.log(userResponse.data.profile_picture.data.toString())
		// 	const base64Image = btoa(
		// 		new Uint8Array(userResponse.data.profile_picture.data)
		// 			.reduce((data, byte) => data + String.fromCharCode(byte), '')
		// 	);
		// 	console.log(`data:image/jpeg;base64,${base64Image}`)
		// 	return `data:image/jpeg;base64,${base64Image}`
		// } catch (error) {
		// 	console.error('Fehler beim Laden des Profilbilds', error)
		// 	return ""
		// }
		return ''
	}

	async function fillLeaderboard() {
		const temp: LeaderboardEntry[] = []
		for (let i = 0; i < resultResponse.length; i++) {
			const entry: LeaderboardEntry = {
				id: i + 1 + '',
				wpm: getWPM(resultResponse[i].words_amount, resultResponse[i].time),
				accuracy: formatAccuracy(resultResponse[i].accuracy),
				language: resultResponse[i].language,
				mode: resultResponse[i].mode,
				time: resultResponse[i].time.toString() + 's',
				words: resultResponse[i].words_amount.toString(),
				user: await getUser(resultResponse[i].user_id),
				profilePicture: await getProfilePicture(resultResponse[i].user_id),
			}
			temp.push(entry)
		}
		temp.sort((a, b) => parseInt(b.wpm) - parseInt(a.wpm))
		setLeaderboard(temp)
	}

	useEffect(() => {
		fetchResults()
	}, [])

	useEffect(() => {
		fillLeaderboard()
	}, [resultResponse])

	return (
		<div className="min-h-[calc(100vh-68px)]">
			<Card className="mx-auto mb-16 mt-10 h-min w-10/12 overflow-hidden rounded-xl" placeholder={undefined}>
				<table className="w-full min-w-max table-auto text-center">
					<thead>
						<tr>
							{TABLE_HEAD.map((head) => (
								<th key={head} className="bg-gray-900 p-4">
									<Typography variant="small" className="flex justify-center leading-none text-yellow-600" placeholder={undefined}>
										{head}
									</Typography>
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{/* {leaderboard.map(({ wpm, accuracy, language, mode, time, words, user, profilePicture }, index) => ( */}
						{leaderboard.map(({ wpm, accuracy, language, mode, time, words, user }, index) => (
							<tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#344054' : '#1A202C' }}>
								<td className="p-4">
									<Typography variant="small" style={{ color: "#E5E7EB" }} className="flex justify-center font-normal" placeholder={undefined}>
										{index + 1}
									</Typography>
								</td>
								<td className="p-4">
									<Typography variant="small" style={{ color: "#E5E7EB" }} className="flex justify-center gap-2 font-normal" placeholder={undefined}>
										{user}
										{/* {profilePicture ? (
										<img src={profilePicture} alt="pic" style={{ width: 25, height: 25, borderRadius: '50%' }} />
									) : (
										<AccountCircleIcon style={{ width: 25, height: 25, borderRadius: '50%' }} />
									)} */}
									</Typography>
								</td>
								<td className="p-4">
									<Typography variant="small" style={{ color: "#E5E7EB" }} className="flex justify-center font-normal" placeholder={undefined}>
										{words}
									</Typography>
								</td>
								<td className="p-4">
									<Typography variant="small" style={{ color: "#E5E7EB" }} className="flex justify-center font-normal" placeholder={undefined}>
										{accuracy}
									</Typography>
								</td>
								<td className="p-4">
									<Typography variant="small" style={{ color: "#E5E7EB" }} className="flex justify-center font-normal" placeholder={undefined}>
										{language}
									</Typography>
								</td>
								<td className="p-4">
									<Typography variant="small" style={{ color: "#E5E7EB" }} className="flex justify-center font-normal" placeholder={undefined}>
										{mode}
									</Typography>
								</td>
								<td className="p-4">
									<Typography variant="small" style={{ color: "#E5E7EB" }} className="flex justify-center font-normal" placeholder={undefined}>
										{time}
									</Typography>
								</td>
								<td className="p-4">
									<Typography variant="small" style={{ color: "#E5E7EB" }} className="flex justify-center font-normal" placeholder={undefined}>
										{wpm}
									</Typography>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</Card>
		</div>
	)
}

export default LeaderboardPage
