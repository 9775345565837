import React from 'react'

import LoginForm from './LoginForm'
import { Navigate } from 'react-router-dom'
// import '@src/styles/login.css'

const LoginPage: React.FC = () => {
	const token = localStorage.getItem('token')
	//Falls User eingeloggt wird man auf die Homepage umgeleitet
	if (token) {
		// Wenn ein Token vorhanden ist, leite den Benutzer zur Startseite um
		return <Navigate to="/" replace />
	}
	return (
		<div className="flex h-[calc(100vh-68px)] w-screen flex-col items-center justify-center">
			<h1 className="-mt-28 mb-9 text-5xl text-white">Welcome back!</h1>
			<div className="flex flex-col">
				<LoginForm />
				<div className="flex gap-2">
					<a className="text-gray-400 hover:text-gray-100" href="/register">
						Register now
					</a>
					|
					<a className="text-gray-400 hover:text-gray-100" href="/forgot-password">
						Forgot password?
					</a>
				</div>
			</div>
		</div>
	)
}

export default LoginPage
