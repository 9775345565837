import React, { useState, useEffect } from 'react'
import Message from '../Message'
// import '@styles/registrationDialogue.css'
interface ModalProps {
	message: string
	icon: React.ReactNode
	onClose: () => void
}

const Modal: React.FC<ModalProps> = ({ message, icon, onClose }) => (
	<div className="modal-overlay">
		<div className="modal">
			<div className="modal-content">
				<p>{message}</p>
				<div className="icon">{icon}</div>
				<button onClick={onClose}>Schließen</button>
			</div>
		</div>
	</div>
)

const LoginForm: React.FC = () => {
	const [email, setEmail] = useState<string>('')
	const [password, setPassword] = useState<string>('')
	const [error, setError] = useState<string>('')
	const [isLoggedIn, setIsLoggedIn] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [showSuccessModal, setShowSuccessModal] = useState(false)

	useEffect(() => {
		// Überprüfen, ob ein Token im localStorage vorhanden ist
		const token = localStorage.getItem('token')
		if (token) {
			setIsLoggedIn(true)
		}
	}, [])

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault()
		if (isLoggedIn) {
			alert('Already logged in')
			return
		}
		try {
			const response = await fetch(import.meta.env.VITE_API_URL + 'auth/login', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email: email,
					password: password,
				}),
				credentials: 'include',
			})

			if (response.status === 200) {
				// Erfolgreiche Anmeldung
				const data = await response.json()
				const token = data.access_token
				// Funktion zum Extrahieren der Benutzer-ID aus dem JWT-Payload
				// Getestet ob userid im backend selbe userid wie im frontend
				// const extractUserIdFromToken = (token: string | undefined) => {
				// 	try {
				// 	  if (!token) {
				// 		console.error('Token ist undefined oder null.');
				// 		return null;
				// 	  }

				// 	  const payloadBase64 = token.split('.')[1];
				// 	  const payload = JSON.parse(atob(payloadBase64));
				// 	  return payload.sub || null; // Annahme: Die Benutzer-ID ist im Feld "sub"
				// 	} catch (error) {
				// 	  console.error('Fehler beim Extrahieren der Benutzer-ID:', error);
				// 	  return null;
				// 	}
				//   };

				//   // Benutzer-ID extrahieren und in der Konsole ausgeben
				//   const userId = extractUserIdFromToken(token);
				//   console.log('Benutzer-ID:', userId)

				localStorage.setItem('token', token)

				setShowSuccessModal(true)
				setTimeout(() => {
					window.location.href = '/'
				}, 1000)
			} else {
				const data = await response.json()
				setError(`Fehler bei der Anmeldung: ${data.error}`)
				setShowModal(true)
			}
		} catch (error: any) {
			setError('E-Mail oder Passwort falsch')
			console.error('Fehler bei der Anmeldung', error.message)
			setShowModal(true)
		}
	}
	const resetForm = () => {
		setEmail('')
		setPassword('')
		setError('')
	}
	return (
		<div>
			<form className="flex flex-col" onSubmit={handleSubmit}>
				{/* {isLoggedIn ? <p className="success">Already logged in</p> : error && <p className="text-white">{error}</p>} */}

				<input
					className="sm:text-md mb-5 block rounded-lg border border-gray-900 bg-gray-800 p-4 text-white"
					type="email"
					name="email"
					id="email"
					placeholder="Email"
					onChange={(e) => setEmail(e.target.value)}
					required
				/>

				<input
					className="sm:text-md mb-5 block rounded-lg border border-gray-900 bg-gray-800 p-4 text-white"
					type="password"
					name="password"
					id="password"
					placeholder="Password"
					onChange={(e) => setPassword(e.target.value)}
					required
				/>

				<button className="mb-5 rounded-lg border-gray-900 bg-gray-900 p-4 text-white hover:text-gray-500" type="submit">
					Login
				</button>
			</form>

			{showModal && (
				<Message message={error} isError={true} />
			)}

			{showSuccessModal && (
				<Message message='You have successfully logged in!' isError={false} />
			)}
		</div>
	)
}

export default LoginForm
