// ForgotPassword.tsx
import React, { useState } from 'react'

const ForgotPassword: React.FC = () => {
	const [email, setEmail] = useState('')

	const handleForgotPassword = async () => {
		try {
			const response = await fetch(import.meta.env.VITE_API_URL + 'auth/forgot-password', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ email }),
			})

			if (response.ok) {
				console.log('E-Mail mit Anweisungen zum Zurücksetzen des Passworts gesendet.')
				alert('E-Mail mit Anweisungen zum Zurücksetzen des Passworts gesendet.')
			} else {
				console.error('Fehler beim Senden der Passwortzurücksetzen-E-Mail.')
				alert('Fehler beim Senden der Passwortzurücksetzen-E-Mail.')
			}
		} catch (error) {
			console.error('Fehler beim Senden der Passwortzurücksetzen-E-Mail:', error)
			alert('Fehler beim Senden der Passwortzurücksetzen-E-Mail:' + error)
		}
	}

	return (
		<div className="flex h-[calc(100vh-68px)] w-screen flex-col items-center justify-center">
			<h1 className="-mt-28 mb-9 text-5xl text-white">Reset password!</h1>
			<div className="flex flex-col">
				<input
					className="sm:text-md mb-5 block rounded-lg border border-gray-900 bg-gray-800 p-4 text-white"
					name="emai"
					type="email"
					placeholder="E-Mail"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<button className="mb-5 rounded-lg border-gray-900 bg-gray-900 p-4 text-white hover:text-gray-500" onClick={handleForgotPassword}>
					Send E-Mail
				</button>
			</div>
		</div>
	)
}

export default ForgotPassword
