/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, createRef, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Word from './Word'

import { useAppDispatch, useAppSelector } from '@src/redux/hooks'
import { selectGame, addInputWord, updateInputWord, addStartIndex, getWordsAsync, toggleNativeWord, incrementCurrentIndex, startGame, decrementCurrentIndex } from './gameSlice'
import NativeWord from './NativeWord'
import { CombinedWord } from '@src/types'

export default function Words(props: { foreignLanguage: string; nativeLanguage: string }) {
	const wordRefs = useRef(Array.from({ length: 101 }, () => createRef<HTMLDivElement>()))
	const inputRef = useRef<HTMLInputElement | null>(null)

	const [input, setInput] = useState<string>('')

	const gameSlice = useAppSelector(selectGame)
	const dispatch = useAppDispatch()

	// get words from backend
	async function getWords() {
		try {
			if (!import.meta.env.VITE_API_URL) {
				return toast.error('install dotenv (npm install) and set VITE_API_URL in .env file')
			}

			dispatch(getWordsAsync({ foreignLang: props.foreignLanguage, nativeLang: props.nativeLanguage }))

			toast.success('words loaded from backend', {
				position: toast.POSITION.BOTTOM_CENTER,
				autoClose: 1000,
			})

			// dispatch(startGame())
		} catch (error) {
			console.error('Fehler beim Laden der Wörter: ', error)
			toast.error('could not get words from server, check your internet connection')
		}
	}

	// executed when component is rendered for first time
	useEffect(() => {
		getWords()

		// startet das Typing sobald eine beliebgige Taste gedrückt wurde
		function startTyping() {
			inputRef.current?.focus()
		}

		// wartet auf keyboard input
		window.addEventListener('keydown', startTyping)

		return () => window.removeEventListener('keydown', startTyping)
	}, [])

	// all this does is rerender the <NativeWord /> component on window resize
	useEffect(() => {
		// Handler to call on window resize
		function handleResize() {
			setInput((prev) => prev + ' ')
		}

		// Add event listener
		window.addEventListener('resize', handleResize)

		// Call handler right away so state gets updated with initial window size
		handleResize()

		// Remove event listener on cleanup
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	// used when anything except space is pressed
	function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
		const { value } = event.target

		inputRef.current!.value = inputRef.current!.value.trim()

		// if (!inputRef.current!.value) return

		if (!gameSlice.nativeWordActive) dispatch(startGame())

		if (gameSlice.settings.mix && gameSlice.nativeWordActive) {
			setInput(value.trim())
		} else {
			setInput('')
			dispatch(updateInputWord(value.trim()))
		}
	}

	// used when space is pressed
	function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
		const { code } = event

		const inputValue = inputRef.current!.value.trim()

		// handle space
		if (code === 'Space' && inputValue) {
			checkNewLine()
			inputRef.current!.value = '' // reset input field

			if (gameSlice.settings.mix) {
				dispatch(toggleNativeWord())
			}

			if (gameSlice.nativeWordActive) {
				dispatch(incrementCurrentIndex())
				return
			}
			dispatch(addInputWord(inputValue))
		}

		// decrement currentIndex if input word is not typed
		if (code === 'Backspace') {
			const input = gameSlice.combinedWords[gameSlice.currentIndex]?.input
			if (input && input.length > 0) return
			dispatch(decrementCurrentIndex())
		}
	}

	// check if next word is in the next line
	function checkNewLine() {
		const currentIndex = gameSlice.currentIndex - gameSlice.startIndex

		const currWord = wordRefs.current[currentIndex]?.current?.getBoundingClientRect()
		const nextWord = wordRefs.current[currentIndex + 1]?.current?.getBoundingClientRect()

		if (currWord && nextWord && currWord.top !== nextWord.top) {
			dispatch(addStartIndex(currentIndex + 1))
		}
	}

	return (
		<>
			{/**
			 * Mapped jedes Wort in foreignWords zu einem Word-Element
			 * Hinter jedes Wort wird mit &nbsp ein Leerzeichen gesetzt
			 * Key lässt sich ignorieren, das ist nur benötigt um React glücklich zu machen
			 */}
			<div className="inline-block break-all rounded-3xl rounded-tr-none bg-gray-900 p-16 py-24 shadow-xl">
				<div className="inline-block max-w-6xl p-1 text-2xl" id="wordsContainer">
					<div
						className="inline-block"
						style={{
							display: '-webkit-box',
							WebkitBoxOrient: 'vertical',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							WebkitLineClamp: 3, // Adjust the number of lines as needed
						}}
					>
						{
							// render <Word /> components
							gameSlice.combinedWords.length > 0
								? gameSlice.combinedWords.slice(gameSlice.startIndex).map((word: CombinedWord, wordIndex: number) => {
										return (
											<Word //
												combinedWord={word}
												index={wordIndex}
												key={wordIndex}
												ref={wordRefs.current[wordIndex]}
											/>
										)
									})
								: // render nativefingers skeleton text
									Array(100).fill('nativefingers').join(' ')
						}
					</div>
					<NativeWord
						word={gameSlice.combinedWords[gameSlice.currentIndex]?.native}
						foreignRect={wordRefs.current[gameSlice.currentIndex - gameSlice.startIndex]?.current?.getBoundingClientRect()}
						input={input}
					/>
				</div>
			</div>

			<input
				type="text"
				onChange={handleOnChange}
				onKeyDown={handleKeyPress}
				ref={inputRef}
				autoFocus
				style={{ opacity: 0 }} // to hide the input field
			/>
			{/* <ToastContainer /> */}
		</>
	)
}
