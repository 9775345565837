import React from 'react'
import { Link } from 'react-router-dom'
import '@styles/verification.css'

const VerificationError = () => {
	return (
		<div className="flex h-[calc(100vh-68px)] w-screen flex-col items-center justify-center">
			<h1 className="text-4xl font-bold text-white">Verifizierung fehlgeschlagen</h1>
			<p className="text-xl text-white">Der Bestätigungscode ist falsch oder der Benutzer wurde nicht gefunden.</p>
		</div>
	)
}

export default VerificationError
