import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import RegistrationPage from './login/RegistrationPage'
import Homepage from './homepage/HomePage'
import LoginPage from './login/LoginPage'
import UserPage from './UserPage'
import VerificationSuccess from './login/VerificationSuccess'
import VerificationError from './login/VerificationError'
import { ThemeContext } from '../ThemeContext'
import { useEffect, useState } from 'react'

import ForgotPassword from './PasswordForgot'
import ResetPassword from './PasswordReset'
import SettingsPage from './SettingsPage'

import Sentences from './homepage/Sentences'
import Navbar from './Navbar'

import FriendsPage from './FriendshipPage'
import FriendshipPage from './FriendshipPage'
import LeaderboardPage from './LeaderboardPage'
import About from './about/About'

function App() {
	//Speichert den aktuellen Theme in den LocalStorage
	const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light')

	//Speichert die Farben der Themes
	const lightTheme: string[] = ['#C1CBFB', '#A3A7DF', '#4866E9', '#000000', 'rgba(19, 14, 1, 0.5)', '#ffffff', '#CF4307', '#f86c30'] //'ff8800']
	const darkTheme: string[] = ['#040e3e', '#20245b', '#1533b7', '#ffffff', 'rgba(234,232,228,0.5)', '#000000', '#CF4307', '#f86c30']

	//Wenn der Theme im LocalStorage geändert wird, wird der Theme im State geändert
	useEffect(() => {
		applyTheme(theme === 'dark' ? darkTheme : lightTheme)
	}, [theme])

	//Setzt die Farben der Themes
	function applyTheme(theme: string[]) {
		document.documentElement.style.setProperty('--background', theme[0])
		// document.documentElement.style.setProperty('--lightBlue', theme[1])
		// document.documentElement.style.setProperty('--darkBlue', theme[2])
		document.documentElement.style.setProperty('--textColor', theme[3])
		document.documentElement.style.setProperty('--textFaded', theme[4])
		document.documentElement.style.setProperty('--invertedText', theme[5])
		document.documentElement.style.setProperty('--red', theme[6])
		document.documentElement.style.setProperty('--orange', theme[7])
	}

	//Wechselt zwischen den Themes
	const toggleTheme = () => {
		const newTheme = theme === 'dark' ? 'light' : 'dark'
		localStorage.setItem('theme', newTheme)
		setTheme(newTheme)
	}

	return (
		<>
			{/* <div className="absolute h-screen w-screen bg-hero-pattern bg-cover bg-fixed "></div>
			<div className="w-screen overflow-x-hidden font-sans backdrop-blur-3xl"> */}
			<div className="w-full overflow-x-hidden bg-hero-pattern bg-cover bg-fixed font-sans">
				{/* Gibt den ThemeContext an alle Komponenten weiter} */}
				<ThemeContext.Provider value={{ theme, toggleTheme }}>
					<Navbar />
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<Homepage />} />
							<Route path="/register" element={<RegistrationPage />} />
							<Route path="/login" element={<LoginPage />} />
							<Route path="/user" element={<UserPage />} />
							<Route path="/verification-success" element={<VerificationSuccess />} />
							<Route path="/verification-error" element={<VerificationError />} />
							<Route path="/forgot-password" element={<ForgotPassword />} />
							<Route path="/reset-password" element={<ResetPassword />} />
							<Route path="/settings" element={<SettingsPage />} />
							<Route path="/friends" element={<FriendshipPage />} />
							<Route path="/leaderboard" element={<LeaderboardPage />} />
							<Route path="/about" element={<About />} />
						</Routes>
					</BrowserRouter>
				</ThemeContext.Provider>
			</div>
			<footer className="btn btn-ghost fixed bottom-0 left-0 p-3 text-white">ProjectX 2024</footer>
		</>
	)
}
export default App
