import React, { useState, useEffect } from 'react'
import FriendResultsComponent from './FriendResultComponent'
import Modal_error from './error_modal'
import fetchWithToken from './login/fetchWithToken'
import GlobalStatsComponent from './GlobalStatsComponent'

type FriendRequest = {
	id: number
	username: string
}
type SentRequest = {
	id: number
	username: string
}

type Friend = {
	id: number
	username: string
}

const FriendshipPage: React.FC = () => {
	const [username, setUsername] = useState<string>('')
	const [pendingRequests, setPendingRequests] = useState<FriendRequest[]>([])
	const [sentRequests, setSentRequests] = useState<SentRequest[]>([])
	const [friendsList, setFriendsList] = useState<Friend[]>([])
	const [activeFriendId, setActiveFriendId] = useState<number | null>(null)
	const [error, setError] = useState<string>('')
	const [showGlobalStats, setShowGlobalStats] = useState<number | null>(null)

	const token = localStorage.getItem('token')
	if (!token) {
		throw new Error('No token found')
	}
	const userId = JSON.parse(atob(token.split('.')[1])).sub

	useEffect(() => {
		fetchPendingRequests()
		fetchSentRequests()
		fetchFriendsList()
	}, [])

	const sendFriendRequest = async () => {
		try {
			const response = await fetchWithToken(import.meta.env.VITE_API_URL + 'friends/addByUsername', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ requesterId: userId, receiverUsername: username }),
			})

			const data = await response.json()
			if (!response.ok) {
				if (data.error) {
					setError(data.error) // Setzt die spezifische Fehlermeldung aus der API-Antwort
				} else {
					setError('Unbekannter Fehler')
				}
			}

			setUsername('') // Reset das Feld nach dem Senden
			fetchSentRequests() // Aktualisiert die Liste der gesendeten Anfragen
		} catch (error) {
			setError('Error sending friend request:' + error)
			console.error('Error sending friend request:', error)
		}
	}

	const fetchPendingRequests = async () => {
		try {
			const response = await fetchWithToken(import.meta.env.VITE_API_URL + 'friends/pendingRequests', {
				headers: { Authorization: `Bearer ${token}` },
			})

			const data = await response.json()

			setPendingRequests(data)
		} catch (error) {
			console.error('Error fetching pending requests:', error)
		}
	}

	const fetchSentRequests = async () => {
		try {
			const response = await fetchWithToken(import.meta.env.VITE_API_URL + 'friends/sentRequests', {
				headers: { Authorization: `Bearer ${token}` },
			})

			const data = await response.json()
			setSentRequests(data)
		} catch (error) {
			console.error('Error fetching sent requests:', error)
		}
	}

	const fetchFriendsList = async () => {
		try {
			const response = await fetchWithToken(import.meta.env.VITE_API_URL + 'friends/friends', {
				headers: { Authorization: `Bearer ${token}` },
			})

			const data = await response.json()

			setFriendsList(data)
		} catch (error) {
			console.error('Error fetching friends list:', error)
		}
	}

	const handleAccept = async (requesterId: number) => {
		try {
			await fetchWithToken(import.meta.env.VITE_API_URL + `friends/accept`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ requesterId, receiverId: userId }),
			})

			fetchPendingRequests() //aktualisiert ausstehende anfragen
			fetchFriendsList() //aktualisiert freundesliste
		} catch (error) {
			console.error('Error accepting request:', error)
		}
	}
	const removeFriend = async (friendId: number) => {
		try {
			await fetchWithToken(import.meta.env.VITE_API_URL + `friends/removeFriend/${friendId}`, {
				method: 'DELETE',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			fetchFriendsList() // Aktualisieren Sie die Freundesliste nach dem Entfernen
		} catch (error) {
			console.error('Error removing friend:', error)
		}
	}

	const handleReject = async (requesterId: number) => {
		try {
			await fetchWithToken(import.meta.env.VITE_API_URL + `friends/reject`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ requesterId, receiverId: userId }),
			})
			fetchPendingRequests() //aktualisiert ausstehende anfragen
		} catch (error) {
			console.error('Error rejecting request:', error)
		}
	}
	const toggleFriendResults = (friendId: number) => {
		setActiveFriendId(activeFriendId === friendId ? null : friendId)
	}

	const toggleGlobalStats = (friendId: number) => {
		setShowGlobalStats(showGlobalStats === friendId ? null : friendId)
	}

	const resetError = () => {
		setError('')
	}
	return (
		<div className="flex h-[calc(100vh-68px)] flex-col items-center justify-center overflow-auto">
			<div className="my-4 w-full max-w-md px-4">
				<input type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Enter username" className="w-full" />
				<button onClick={sendFriendRequest} className="mt-2 w-full" disabled={!username}>
					Send Friend Request
				</button>
			</div>

			<div className="my-4 w-full max-w-md px-4">
				<h2>Pending Requests</h2>
				{pendingRequests.map((request) => (
					<div key={request.id} className="mb-2 flex justify-between">
						{request.username}
						<div>
							<button onClick={() => handleAccept(request.id)}>Accept</button>
							<button onClick={() => handleReject(request.id)}>Reject</button>
						</div>
					</div>
				))}
			</div>

			<div className="my-4 w-full max-w-md px-4">
				<h2>Sent Requests</h2>
				{sentRequests.map((request) => (
					<div key={request.id} className="mb-2">
						{request.username}
					</div>
				))}
			</div>

			<div className="w-full max-w-md px-4">
				<h2>Friends List</h2>
				{friendsList.map((friend) => (
					<div key={friend.id} className="mb-4">
						<div className="mb-2 flex items-center justify-between">
							<span className="text-blue-500">{friend.username}</span>

							<div>
								<button onClick={() => removeFriend(friend.id)} className="mr-2">
									Remove Friend
								</button>
								<button onClick={() => toggleGlobalStats(friend.id)}>{showGlobalStats === friend.id ? 'Hide Global Stats' : 'Show Global Stats'}</button>
							</div>
						</div>
						{showGlobalStats === friend.id && (
							<>
								<GlobalStatsComponent friendId={friend.id} token={token} />
								<button onClick={() => toggleFriendResults(friend.id)} className="mt-2">
									{activeFriendId === friend.id ? 'Hide Last 5 Runs' : 'Show Last 5 Runs'}
								</button>
							</>
						)}
						{activeFriendId === friend.id && (
							<div className="mt-2 flex overflow-x-auto">
								<FriendResultsComponent friendId={friend.id} token={token} />
							</div>
						)}
					</div>
				))}
			</div>

			{error && <Modal_error message={error} icon={<span style={{ color: 'red' }}>✘</span>} onClose={resetError} />}
		</div>
	)
}

export default FriendshipPage
