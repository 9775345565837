import refreshAccessToken from "./refreshAccessToken";

// fetchWithToken.ts
async function fetchWithToken(url: string, options: RequestInit = {}) {
    let token = localStorage.getItem('token');
    // console.log("Fetching URL with token: ", url, token);

    // if (!token) {
    //     // console.log(" no token found in local storage - fetchWithTokenFunc")
    // }

    const headers = new Headers(options.headers);
    headers.set('Authorization', `Bearer ${token}`);
    options.headers = headers;

    let response = await fetch(url, options);

    if (response.status === 401) {
        console.log('Access token expired, attempting to refresh');
        const refreshed = await refreshAccessToken();
        if (refreshed) {
            token = localStorage.getItem("token");
            headers.set('Authorization', `Bearer ${token}`);
            response = await fetch(url, { ...options, headers });
        }
    }

    return response;
}

export default fetchWithToken;

