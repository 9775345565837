import { useAppSelector } from '@src/redux/hooks'
import { selectGame } from './gameSlice'
import { accentCleanse } from '@src/util'

interface NativeWordProps {
	word: string | undefined
	foreignRect: DOMRect | undefined
	// input: React.Dispatch<React.SetStateAction<string>>
	input: string
}

export default function NativeWord({ word, foreignRect, input }: NativeWordProps) {
	const gameSlice = useAppSelector(selectGame)

	if (!word || !foreignRect) return

	function determineClassName() {
		return gameSlice.settings.mix && gameSlice.nativeWordActive ? 'absolute bg-gray-700 rounded-lg p-1 -m-1' : 'absolute'
	}

	function determineNativeLetterColor(letterIndex: number): string {
		if (!gameSlice.nativeWordActive) return 'text-gray-400'
		if (!word || !input || input[letterIndex] === undefined) {
			// return 'notTypedNative' // no input
			return 'text-gray-400'
		} else if (input.length > word.length) {
			// return 'tooLong' // word too long
			return 'text-orange-500'
		} else if (accentCleanse(word[letterIndex], gameSlice.settings.strict) === accentCleanse(input[letterIndex], gameSlice.settings.strict)) {
			// return 'correct' // correct letter
			return 'text-white'
		} else {
			// return 'incorrect' // wrong letter
			return 'text-red-400'
		}
	}

	return (
		<div
			className={determineClassName()}
			style={{
				top: foreignRect.top - foreignRect.height,
				left: foreignRect.left,
			}}
		>
			{word.split('').map((letter, letterIndex) => (
				<span className={determineNativeLetterColor(letterIndex)} key={letterIndex}>
					{letter}
				</span>
			))}
		</div>
	)
}
