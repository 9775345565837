export default function About() {
	return (
		<div className="flex h-[calc(100vh-68px)] flex-col items-center justify-center">
			<div className="rounded-xl bg-gray-900 p-10 " style={{ color: '#E5E7EB' }}>
				Hi there!
				<br />
				nativefingers is a typing website focused on learning vocabulary through muscle memory.
				<br />
				Our dev team consists of 5 bachelor students.
				<br />
				We made this website as our final semester project.
				<br />
				Hope you enjoy.
				<br />
				<br />
				<br />
				These are the technolgies we used to make it possible:
				<br />
				<br />
				<img className="m-2 inline-block w-20" alt="react" src="./assets/public/react.png" />
				<img className="m-2 inline-block w-20" alt="redux" src="./assets/public/redux.png" />
				<img className="m-2 inline-block w-20" alt="tailwind" src="./assets/public/tailwind.webp" />
				<img className="m-2 inline-block w-20" alt="vite" src="./assets/public/vite.png" />
				<img className="m-2 inline-block w-20" alt="ts" src="./assets/public/ts.png" />
				<img className="m-2 inline-block w-20" alt="express" src="./assets/public/express.png" />
				<img className="m-2 inline-block w-20" alt="postgresql" src="./assets/public/postgresql.png" />
				<img className="m-2 inline-block w-20" alt="docker" src="./assets/public/docker.png" />
			</div>
		</div>
	)
}
