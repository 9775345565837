import '@src/styles/modiBar.css'
import { useAppDispatch, useAppSelector } from '@src/redux/hooks'
import { selectGame, setSettings } from './gameSlice'

function ModiBar() {
	const gameSlice = useAppSelector(selectGame)
	const dispatch = useAppDispatch()

	function setValue(value: number) {
		const newSettings = { ...gameSlice.settings }
		newSettings.value = value
		dispatch(setSettings(newSettings))
	}

	// values for modibar buttons
	const timeValues = [15, 30, 60, 120]
	const wordValues = [25, 50, 100, 200]

	const values = gameSlice.settings.mode === 'words' ? wordValues : timeValues

	return (
		<div className="flex justify-center gap-1 p-2 px-2 text-gray-200">
			<button
				className={`btn btn-ghost btn-xs ${gameSlice.settings.mix ? 'text-yellow-600' : ''}`}
				onClick={() => dispatch(setSettings({ ...gameSlice.settings, mix: !gameSlice.settings.mix }))}
			>
				mix mode
			</button>
			<button
				className={`btn btn-ghost btn-xs ${gameSlice.settings.strict ? 'text-yellow-600' : ''}`}
				onClick={() => dispatch(setSettings({ ...gameSlice.settings, strict: !gameSlice.settings.strict }))}
			>
				{' '}
				strict mode
			</button>
			<div style={{ width: '1px', height: '25px', backgroundColor: 'white', margin: '0 5px' }}></div>
			<button
				className={`btn btn-ghost btn-xs ${gameSlice.settings.mode === 'time' ? 'text-yellow-600' : ''}`}
				onClick={() => dispatch(setSettings({ ...gameSlice.settings, mode: 'time', value: 30 }))}
			>
				time
			</button>
			<button
				className={`btn btn-ghost btn-xs ${gameSlice.settings.mode === 'words' ? 'text-yellow-600' : ''}`}
				onClick={() => dispatch(setSettings({ ...gameSlice.settings, mode: 'words', value: 50 }))}
			>
				words
			</button>
			{/* <button
				className={`btn btn-ghost btn-xs ${props.settings.mode === 'sentences' ? 'text-yellow-600' : ''}`}
				onClick={() => props.setSettings({ ...props.settings, mode: 'sentences' })}
			>
				sentences
			</button> */}

			<div style={{ width: '1px', height: '25px', backgroundColor: 'white', margin: '0 5px' }}></div>

			{/* {gameSlice.settings.mode === 'sentences' ? (
				<p></p>
			) : (
				<div className=""> */}
			<button className={`btn btn-ghost btn-xs ${gameSlice.settings.value === values[0] ? 'text-yellow-600' : ''}`} onClick={() => setValue(values[0])}>
				{values[0]}
			</button>
			<button className={`btn btn-ghost btn-xs ${gameSlice.settings.value === values[1] ? 'text-yellow-600' : ''}`} onClick={() => setValue(values[1])}>
				{values[1]}
			</button>
			<button className={`btn btn-ghost btn-xs ${gameSlice.settings.value === values[2] ? 'text-yellow-600' : ''}`} onClick={() => setValue(values[2])}>
				{values[2]}
			</button>
			<button className={`btn btn-ghost btn-xs ${gameSlice.settings.value === values[3] ? 'text-yellow-600' : ''}`} onClick={() => setValue(values[3])}>
				{values[3]}
			</button>
			{/* </div>
			)} */}
		</div>
	)
}

export default ModiBar
