import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react'
import axios from 'axios'
import bcrypt from 'bcryptjs'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const SettingsPage: React.FC = () => {
	const [userId, setUserId] = useState('')
	const [username, setName] = useState('')
	const [password, setPassword] = useState('')
	const [profilePicture, setProfilePicture] = useState<File | null>(null)
	const [isPasswordEnabled, setIsPasswordEnabled] = useState(false)
	const [newPassword1, setNewPassword1] = useState('')
	const [newPassword2, setNewPassword2] = useState('')
	const [isOldPasswordEntered, setIsOldPasswordEntered] = useState(false)
	const [oldPassword, setOldPassword] = useState('')
	const [oldPassBackend, setOldPassBackend] = useState<string | null>(null) // Initialisierung mit null oder leerem String, je nach deinen Anforderungen
	const [showChangeButton1, setShowChangeButton1] = useState(false)
	const [showChangeButton2, setShowChangeButton2] = useState(false)
	const [profilePictureUrl, setProfilePictureUrl] = useState<string | null>(null)
	const [isEditingUsername, setIsEditingUsername] = useState(false)
	const [hasChanges, setHasChanges] = useState(false)

	const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value)
		setIsEditingUsername(true)
		setHasChanges(true)
	}

	const handleChangePassword = async () => {
		if (newPassword1 === newPassword2) {
			try {
				const hashedPassword = bcrypt.hashSync(newPassword1, 10)

				// // Check ob das Passwort das gleiche ist
				// const response = await axios.get(import.meta.env.VITE_API_URL + `user/${userId}`, {
				//     headers: {
				//         Authorization: `Bearer ${token}`,
				//     },
				// });

				// const user = response.data;
				// const passwordsMatch = bcrypt.compareSync(newPassword1, user.password);

				// Update the password
				const formData = new FormData()

				formData.append('id', userId)
				formData.append('username', username)
				formData.append('password', hashedPassword)

				//Ändere nur das Passwort
				// console.log(userId)
				await axios.put(import.meta.env.VITE_API_URL + `user`, formData, {})
				toast.success('You have successfully changed your password')
				//Passwortfelder leeren
				setNewPassword1('')
				setNewPassword2('')
				setOldPassword('')
				//Passwort menü wieder ausblenden
				setIsPasswordEnabled(false)
				setIsOldPasswordEntered(false)
				// Weitere Aktualisierungen und Rücksetzungen hier...
			} catch (error) {
				console.error('Error while updating the user:', error)
			}
		} else {
			// Die beiden neuen Passwörter stimmen nicht überein
			toast.error('The two new passwords do not match.')
		}
	}

	const handleNewPassword1Change = (event: ChangeEvent<HTMLInputElement>) => {
		setNewPassword1(event.target.value)
		setShowChangeButton1(true)
		setHasChanges(true)
	}

	const handleNewPassword2Change = (event: ChangeEvent<HTMLInputElement>) => {
		setNewPassword2(event.target.value)
		setShowChangeButton2(true)
		setHasChanges(true)
	}

	const handleOldPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
		setOldPassword(event.target.value)
		setHasChanges(true)
	}

	const handleProfilePictureChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files.length > 0) {
			const selectedFile = event.target.files[0]
			setProfilePicture(selectedFile)
			setHasChanges(true)
			//Filename speichern
			localStorage.setItem('uploadedImageFileName', selectedFile.name)
			// console.log(selectedFile.name)
		}
	}

	const handleEnablePassword = () => {
		setIsPasswordEnabled(true)
	}

	const handleOldPasswordEntered = () => {
		// console.log('oldPassword: ', oldPassword);
		// console.log('oldPassBackend: ', oldPassBackend);
		const passwordsMatch = oldPassBackend ? bcrypt.compareSync(oldPassword, oldPassBackend) : false

		if (passwordsMatch) {
			setIsOldPasswordEntered(true)
			// console.log('passwords match!')
		} else {
			// Das alte Passwort stimmt nicht überein
			toast.error('The old password does not match.')
		}
	}

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		// console.log('handelSubmit')
		if (!hasChanges) {
			toast.error('You have not edited anything yet.')
			return
		}
		const reader = new FileReader()

		reader.onloadend = async () => {
			try {
				// Konvertiere das Bild in Base64
				const base64String = reader.result?.toString().split(',')[1]
				// console.log('here ' + base64String)
				// Erstelle das Datenobjekt
				const userData = {
					id: userId,
					username: username,
					password: password,
					profile_picture: base64String || null,
				}

				// console.log(userData)

				// Sende die Daten als JSON
				const response = await fetch(import.meta.env.VITE_API_URL + 'user', {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
					},
					//------------------------------------
					body: JSON.stringify(userData),
				})

				if (!response.ok) {
					// Wenn die Anfrage fehlschlägt, werfe den Fehler
					throw new Error(`Failed to update user. ${response.status} - ${response.statusText}`)
				}

				console.log('User updated successfully.')
				setProfilePictureUrl(`data:image/jpeg;base64,${base64String}`)
				//bild speichern
				localStorage.setItem('profilePictureUrl', `data:image/jpeg;base64,${base64String}`)
				setIsEditingUsername(false)
			} catch (error) {
				console.error('Error updating user:', (error as Error).message)
				toast.error((error as Error).message)
			}
		}

		if (profilePicture !== null) {
			// Lese das Bild als Base64
			reader.readAsDataURL(profilePicture as Blob)
		} else {
			// Wenn kein Bild vorhanden ist, rufe die Funktion ohne Bild auf
			const dummyEvent = {} as ProgressEvent<FileReader>
			reader.onloadend(dummyEvent)
		}
	}

	const token = localStorage.getItem('token')

	// Funktion zum Extrahieren der Benutzer-ID aus dem JWT-Payload
	const extractUserIdFromToken = (token: string | undefined) => {
		try {
			if (!token) {
				console.error('Token is undefined or null.')
				return null
			}

			const payloadBase64 = token.split('.')[1]
			const payload = JSON.parse(atob(payloadBase64))
			return payload.sub || null // Annahme: Die Benutzer-ID ist im Feld "sub"
		} catch (error) {
			console.error('Fehler beim Extrahieren der Benutzer-ID:', error)
			return null
		}
	}

	useEffect(() => {
		if (token) {
			//Benutzer-ID extrahieren und in der Konsole ausgeben
			const extracteduserId = extractUserIdFromToken(token)
			setUserId(extracteduserId)

			// Überprüfe, ob ein gespeichertes Profilbild vorhanden ist
			const storedProfilePictureUrl = localStorage.getItem('profilePictureUrl')
			const storedUploadedImageFileName = localStorage.getItem('uploadedImageFileName')

			// console.log(storedUploadedImageFileName)
			if (storedProfilePictureUrl) {
				setProfilePictureUrl(storedProfilePictureUrl)
			}

			// console.log(' FRONTEND Benutzer-ID:', userId)

			const fetchUser = async () => {
				try {
					const response = await axios.get(import.meta.env.VITE_API_URL + `user/${userId}`, {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					})

					const user = response.data
					// console.log(user);
					setOldPassBackend(user.password) // Passwort aus der Datenbank in oldPassBackend speichern
					// Username ins username Feld eintragen

					if (!isEditingUsername) {
						setName(user.username)
					}

					setPassword(user.password)
				} catch (error) {
					console.error('Error fetching user:', error)
				}
			}

			fetchUser()
		} else {
			console.error('Token ist undefined oder null.')
		}
	}, [token, isEditingUsername, userId])

	if (token) {
		return (
			<div className="flex min-h-screen flex-col items-center justify-center" style={{ marginTop: '68px' }}>
				<h1 className="mb-9 text-5xl text-white">Welcome back!</h1>
				<form className="flex flex-col items-center" onSubmit={handleSubmit}>
					<input className="" type="hidden" value={userId} readOnly />
					<br />
					{profilePictureUrl !== null && (
						<div className="flex items-center justify-center">
							<img className="profile-picture  w-1/2 rounded-full border-4 border-gray-800" src={profilePictureUrl} alt="Profile" />
						</div>
					)}
					<br />
					<br />
					<label htmlFor="username" className="text-white">Username :</label>
					<br />
					<br />
					<input
						className="sm:text-md mb-5 block rounded-lg border border-gray-900 bg-gray-800 p-4 text-white"
						type="text"
						value={username}
						onChange={handleNameChange}
					/>
					<br />
					<br />
					{isPasswordEnabled ? (
						<>
							{isOldPasswordEntered ? (
								<>
									<input
										className="sm:text-md mb-5 block rounded-lg border border-gray-900 bg-gray-800 p-4 text-white"
										type="password"
										placeholder="New Password"
										value={newPassword1}
										onChange={handleNewPassword1Change}
									/>
									<input
										className="sm:text-md mb-5 block rounded-lg border border-gray-900 bg-gray-800 p-4 text-white"
										type="password"
										placeholder="Repeat New Password"
										value={newPassword2}
										onChange={handleNewPassword2Change}
									/>
									{showChangeButton1 && showChangeButton2 && (
										<button
											className="sm:text-md mb-5 block rounded-lg border border-gray-900 bg-gray-800 p-4 text-white"
											type="button"
											onClick={handleChangePassword}
										>
											Change Password
										</button>
									)}
								</>
							) : (
								<>
									<input
										className="sm:text-md mb-5 block rounded-lg border border-gray-900 bg-gray-800 p-4 text-white"
										type="password"
										placeholder="Old password"
										value={oldPassword}
										onChange={handleOldPasswordChange}
									/>
									<button
										className="mb-5 rounded-lg border-gray-900 bg-gray-900 p-4 text-white hover:text-gray-500"
										type="button"
										onClick={handleOldPasswordEntered}
									>
										Enter Old Password
									</button>
								</>
							)}
						</>
					) : (
						<button className="mb-5 rounded-lg border-gray-900 bg-gray-900 p-4 text-white hover:text-gray-500" type="button" onClick={handleEnablePassword}>
							Enable Password Change
						</button>
					)}
					{
						<>
							<div className="mb-4">
								<label className="mb-4 block text-white">Profile Picture :</label>
							</div>
							<div className="mb-4">
								<input
									type="file"
									accept=".jpg, .jpeg, .png"
									max="10MB"
									onChange={handleProfilePictureChange}
									className="mt-2 rounded-md border bg-gray-800 p-2 text-white"
								/>
							</div>
						</>
					}
					{!isPasswordEnabled && (
						<button className="mb-5 rounded-lg border-gray-900 bg-gray-900 p-4 text-white hover:text-gray-500" type="submit">
							Save
						</button>
					)}
				</form>
				{/* ToastContainer am Ende der Komponente hinzufügen */}
				<ToastContainer />
			</div>
		)
	}
}

export default SettingsPage
