import '@styles/words.css'
import { accentCleanse } from '@src/util'
import { forwardRef } from 'react'
import { CombinedWord } from '@src/types'

import { useAppSelector } from '@src/redux/hooks'
import { selectGame } from './gameSlice'

interface WordProps {
	combinedWord: CombinedWord | undefined
	index: number
}

const textColorArray = ['text-orange-400', 'text-yellow-400', 'text-green-400', 'text-blue-400', 'text-indigo-400', 'text-purple-400', 'text-pink-400']
const bgColorArray = ['bg-orange-200', 'bg-yellow-200', 'bg-green-200', 'bg-blue-200', 'bg-indigo-200', 'bg-purple-200', 'bg-pink-200']

const Word = forwardRef<HTMLDivElement, WordProps>(({ combinedWord, index }, ref) => {
	const gameSlice = useAppSelector(selectGame)

	function determineLetterColor(letterIndex: number) {
		if (!combinedWord?.input || combinedWord?.input[letterIndex] === undefined) {
			return 'text-gray-200' // no input
			// return 'gray'
		} else if (combinedWord?.input.length > combinedWord?.foreign.length) {
			return 'text-red-400' // word too long
			// return 'red'
		} else if (accentCleanse(combinedWord?.foreign[letterIndex], gameSlice.settings.strict) === accentCleanse(combinedWord?.input[letterIndex], gameSlice.settings.strict)) {
			return textColorArray[index % textColorArray.length] // correct letter
		} else {
			return 'text-white underline' // wrong letter
			// return 'white'
		}
	}

	// returns foreignWord or inputWord prop from CombinedWord depending on which is longer
	function inputOrForeignWord() {
		if (!combinedWord || !combinedWord.foreign) return ''
		if (!combinedWord.input) return combinedWord.foreign
		const foreignLenght = combinedWord?.foreign.length
		const inputLength = combinedWord?.input?.length

		if (foreignLenght >= inputLength) {
			return combinedWord.foreign
		} else {
			return combinedWord.input
		}
	}

	return (
		<div className={'wordContainer'}>
			{/* NATIVE WORD */}
			{/* FOREIGN WORD */}
			<div className="inline-block" ref={ref}>
				{inputOrForeignWord()
					.split('')
					.map((letter, letterIndex) => (
						<span className={determineLetterColor(letterIndex)} key={letterIndex}>
							{letter}
						</span>
					))}
			</div>
			&nbsp;
		</div>
	)
})

Word.displayName = 'Word'

export default Word
