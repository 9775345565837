import { useState, useEffect } from 'react'
import '../styles/userPage.css'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import axios from 'axios'
import { toast } from 'react-toastify'
import { User } from '../types'

function UserPage() {
	const token = localStorage.getItem('token')
	let userId: number | null = null
	const [profilePictureUrl, setProfilePictureUrl] = useState<string | null>(null)
	const [user, setUser] = useState<UserResource | null>(null)
	const [results, setResults] = useState<Result[]>([]) // change unknown to result type

	// Funktion zum Extrahieren der Benutzer-ID aus dem JWT-Payload
	// Getestet ob userid im backend selbe userid wie im frontend
	const extractUserIdFromToken = (token: string | undefined) => {
		try {
			if (!token) {
				console.error('Token ist undefined oder null.')
				return null
			}
			const payloadBase64 = token.split('.')[1]
			const payload = JSON.parse(atob(payloadBase64))
			return payload.sub || null // Annahme: Die Benutzer-ID ist im Feld "sub"
		} catch (error) {
			console.error('Fehler beim Extrahieren der Benutzer-ID:', error)
			return null
		}
	}

	async function getUser() {
		try {
			const userResponse = await axios.get(import.meta.env.VITE_API_URL + 'user/' + userId)
			setUser(userResponse.data)
		} catch (error) {
			console.error('Fehler beim Laden des Benutzers', error)
			return toast.error('could not load user from server')
		}
	}

	async function getResults() {
		try {
			const resultsResponse = await axios.get(import.meta.env.VITE_API_URL + 'results/user/' + userId)
			setResults(resultsResponse.data as Result[])
		} catch (error) {
			console.error('Fehler beim Laden der Ergebnisse', error)
			return toast.error('could not load results from server')
		}
	}

	useEffect(() => {
		if (token) {
			userId = extractUserIdFromToken(token)
			// Hier Bild aus localStorage abrufen
			const storedProfilePictureUrl = localStorage.getItem('profilePictureUrl')
			if (storedProfilePictureUrl) {
				// Hier können Sie das Bild verwenden oder es in Ihrem State speichern
				setProfilePictureUrl(storedProfilePictureUrl)
			}

			getUser()
			getResults()
		} else {
			window.location.href = '/'
		}
	}, [])

	function getUserDenomination(accuracy: number | undefined): string {
		if (!accuracy) return 'Alphabet Apprentice'
		if (accuracy >= 0.9) {
			return 'Precision Prodigy'
		} else if (accuracy >= 0.8) {
			return 'Keystroke King'
		} else if (accuracy >= 0.7) {
			return 'Typing Titan'
		} else if (accuracy >= 0.6) {
			return 'Word Wizard'
		} else if (accuracy >= 0.5) {
			return 'Keyboard Cadet'
		} else {
			return 'Alphabet Apprentice'
		}
	}

	type Result = {
		id?: string
		words_amount: number
		time: number
		wpm: number
		accuracy: number
		language: string
		mode: string
		user_id: number
	}

	type UserResource = {
		id?: string
		username: string
		email: string
		password?: string
		accuracy?: number
		total_runs?: number
		total_words?: number
		total_time?: number
		highscore?: number
		streak?: number
		status?: 'user' | 'early_adopter' | 'vip' | 'dev'
		is_confirmed?: boolean
		verification_code?: string
		profile_picture?: string
		created_at?: string // oder einen anderen passenden Typ für das Datum verwenden
	}

	return (
		<>
			<div className="m-10 flex min-h-[calc(100vh-68px)] flex-col items-center justify-center gap-8">
				<div className="stats shadow" style={{ backgroundColor: '#14191E' }}>
					<div className="stat">
						<div className="stat-title" style={{ color: '#CCCCCC' }}>Username</div>
						<div className="stat-value text-primary">{user?.username ?? 'username'}</div>
						<div className="stat-desc" style={{ color: '#CCCCCC' }}>{user?.status ?? 'user'}</div>
					</div>

					<div className="stat" >
						<div className="stat-figure text-secondary">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block h-8 w-8 stroke-current">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path>
							</svg>
						</div>
						<div className="stat-title" style={{ color: '#CCCCCC' }}>Completed runs</div>
						<div className="stat-value text-secondary">{user?.total_runs ?? 0}</div>
						<div className="stat-desc" style={{ color: '#CCCCCC' }}>more than 21% of users</div>
					</div>

					<div className="stat">
						<div className="stat-figure text-secondary">
							<div className="avatar online">
								<div className="w-16 rounded-full">
									<img src="https://daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg" />
								</div>
							</div>
						</div>
						<div className="stat-value" style={{ color: '#CCCCCC' }}>{(user?.accuracy) ? (user?.accuracy * 100).toString().slice(0, 2) : (0)}%</div>
						<div className="stat-title" style={{ color: '#CCCCCC' }}>Accuracy</div>
						<div className="stat-desc text-secondary">{getUserDenomination(user?.accuracy)}</div>
					</div>
				</div>

				<div className="stats overflow-y-auto shadow" style={{ backgroundColor: '#14191E' }}>
					<table className="table">
						{/* head */}
						<thead>
							<tr style={{ color: '#CCCCCC' }}>
								<th></th>
								<th >Language</th>
								<th>Words</th>
								<th>Time</th>
								<th>Accuracy</th>
								<th>WPM</th>
								<th>Mode</th>
							</tr>
						</thead>
						<tbody>
							{/* rows */}
							{Array.isArray(results) && results.map((result, resultIndex) => (
								<tr style={{ color: '#CCCCCC' }} key={resultIndex}>
									<th>{resultIndex}</th>
									<td>{result.language}</td>
									<td>{result.words_amount}</td>
									<td>{result.time}</td>
									<td>{(result.accuracy * 100).toString().slice(0, 2)}%</td>
									<td>{result.wpm}</td>
									<td>{result.mode}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</>
	)

}

export default UserPage
