import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState, AppThunk } from '@src/redux/store'
import { CombinedWord, Settings, WordsResponse } from '@src/types'
import { sanitizeWords } from '@src/util'
import axios from 'axios'

export interface GameState {
	gameRunning: boolean
	gameOver: boolean
	foreignLang: string
	nativeLang: string
	settings: Settings
	keyStrokes: number[]
	combinedWords: CombinedWord[]
	currentIndex: number
	startIndex: number
	nativeWordActive: boolean
	status: 'idle' | 'pending' | 'failed'
}

const initialState: GameState = {
	gameRunning: false,
	gameOver: false,
	foreignLang: 'es',
	nativeLang: 'de',
	settings: { mode: 'time', ellapsed: 60, value: 60, mix: false, strict: false },
	keyStrokes: [],
	combinedWords: [],
	currentIndex: 0,
	startIndex: 0,
	nativeWordActive: false,
	status: 'idle',
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const incrementAsync = createAsyncThunk('game/fetchGame', async (amount: number) => {
// 	const response = await fetchGame(amount)
// 	// The value we return becomes the `fulfilled` action payload
// 	return response.data
// })

export const getWordsAsync = createAsyncThunk('game/getWordsAsync', async (credentials: { foreignLang: string; nativeLang: string }) => {
	// console.log('thunk:', credentials.foreignLang, credentials.nativeLang)

	// Anfragen der Wörter vom Server
	const foreignResponse = await axios.get<WordsResponse>(import.meta.env.VITE_API_URL + 'words/' + credentials.foreignLang)
	const nativeResponse = await axios.get<WordsResponse>(import.meta.env.VITE_API_URL + 'words/' + credentials.nativeLang)

	const sanitizedForeign = sanitizeWords(foreignResponse.data.words)
	const sanitizedNative = sanitizeWords(nativeResponse.data.words)

	return sanitizedForeign.map((word, index) => {
		return {
			foreign: word,
			native: sanitizedNative[index],
			input: undefined,
		}
	})
})

export const gameSlice = createSlice({
	name: 'game',
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		startGame: (state) => {
			// 	// Redux Toolkit allows us to write "mutating" logic in reducers. It
			// 	// doesn't actually mutate the state because it uses the Immer library,
			// 	// which detects changes to a "draft state" and produces a brand new
			// 	// immutable state based off those changes
			state.gameRunning = true
		},
		pauseGame: (state) => {
			state.gameRunning = false
		},
		finishGame: (state) => {
			state.gameOver = true
		},
		resetGame: (state) => {
			state.gameRunning = false
			state.gameOver = false
			state.combinedWords = []
			// state.keyStrokes = []
			// state.time = 0
			// state = initialState
		},
		// Use the PayloadAction type to declare the contents of `action.payload`
		updateInputWord: (state, action: PayloadAction<string>) => {
			if (state.settings.mix && state.nativeWordActive) return
			state.combinedWords[state.currentIndex].input = action.payload || ''
		},
		addInputWord: (state, action: PayloadAction<string>) => {
			if (state.settings.mix && state.nativeWordActive) return
			state.combinedWords[state.currentIndex++].input = action.payload || ''
		},
		clearInputWords: (state) => {
			state.combinedWords.map((combinedWord) => {
				return (combinedWord.input = undefined)
			})
		},
		setForeignWords: (state, action: PayloadAction<string[]>) => {
			action.payload.forEach((word, wordIndex) => {
				state.combinedWords[wordIndex].foreign = word
			})
		},
		setSettings: (state, action: PayloadAction<Settings>) => {
			state.settings = action.payload
		},
		addKeyStroke: (state, action: PayloadAction<number>) => {
			state.keyStrokes.push(action.payload)
		},
		clearKeyStrokes: (state) => {
			state.keyStrokes = []
		},

		setCombinedWords: (state, action: PayloadAction<CombinedWord[]>) => {
			state.combinedWords = action.payload
		},
		incrementCurrentIndex: (state) => {
			state.currentIndex++
		},
		decrementCurrentIndex: (state) => {
			if (state.currentIndex - 1 < 0) return
			state.combinedWords[state.currentIndex - 1].input = ''
			state.currentIndex--
		},
		addStartIndex: (state, action: PayloadAction<number>) => {
			state.startIndex += action.payload
		},
		toggleNativeWord: (state) => {
			// console.log(state.nativeWordActive)
			state.nativeWordActive = !state.nativeWordActive
			// console.log(state.nativeWordActive)
			if (state.nativeWordActive) {
				state.gameRunning = false
				// console.log('false')
			} else {
				state.gameRunning = true
				// console.log('true')
			}
		},
	},
	// The `extraReducers` field lets the slice handle actions defined elsewhere,
	// including actions generated by createAsyncThunk or in other slices.
	extraReducers: (builder) => {
		builder
			.addCase(getWordsAsync.pending, (state) => {
				state.status = 'pending'
			})
			.addCase(getWordsAsync.fulfilled, (state, action) => {
				state.status = 'idle'
				state.combinedWords = action.payload
				state.currentIndex = 0
				state.startIndex = 0
			})
			.addCase(getWordsAsync.rejected, (state) => {
				state.status = 'failed'
			})
	},
})

export const {
	startGame,
	pauseGame,
	finishGame,
	resetGame,
	updateInputWord,
	addInputWord,
	clearInputWords,
	setForeignWords,
	setSettings,
	addKeyStroke,
	clearKeyStrokes,
	setCombinedWords,
	incrementCurrentIndex,
	decrementCurrentIndex,
	addStartIndex,
	toggleNativeWord,
} = gameSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.game.value)`
export const selectGame = (state: RootState) => state.game

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
// 	(amount: number): AppThunk =>
// 	(dispatch, getState) => {
// 		const currentValue = selectGame(getState())
// 		if (currentValue % 2 === 1) {
// 			dispatch(incrementByAmount(amount))
// 		}
// 	}

export default gameSlice.reducer
