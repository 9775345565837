import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LeaderboardIcon from '@mui/icons-material/Leaderboard'
import Logout from './Logout'
import { useEffect } from 'react'
import React from 'react'

export default function Navbar() {
	const token = localStorage.getItem('token')
	const [profilePictureUrl, setProfilePictureUrl] = React.useState<string | null>(null)

	useEffect(() => {
		const storedProfilePictureUrl = localStorage.getItem('profilePictureUrl')

		if (storedProfilePictureUrl) {
			setProfilePictureUrl(storedProfilePictureUrl)
		}
	}, [])

	return (
		<div className="navbar">
			<div className="navbar-start">
				{/* alternative navbar layout for narrow screens: */}
				<div className="dropdown">
					<div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
						<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
						</svg>
					</div>
					<ul tabIndex={0} className="menu dropdown-content menu-sm z-[1] mt-3 w-52 rounded-box p-2 shadow">
						<li>
							<a href="/leaderboard">Leaderboards</a>
						</li>
						<li>
							<a href="/about">About</a>
						</li>
					</ul>
				</div>
				<a href="/" className="btn btn-ghost text-xl text-white">
					{/* <a href="/" className="btn btn-ghost text-xl text-white" style={{ backgroundColor: "#2D3748" }}> */}

					nativefingers
				</a>
			</div>
			<div className="navbar-center hidden lg:flex">
				<ul className="menu menu-horizontal px-1">
					<li>
						<a href="/leaderboard" style={{ color: '#E5E7EB' }}>Leaderboard</a>

					</li>
					<li>
						<a href="/about" style={{ color: '#E5E7EB' }}>About</a>
					</li>
				</ul>
			</div>
			<div className="navbar-end">
				<div className="mr-2 flex-none" id="dropdown">
					<div className="dropdown dropdown-end">
						<div tabIndex={0} role="button" className="avatar btn btn-circle btn-ghost" id="profile pic">
							<div className="h-10 w-10 overflow-hidden rounded-full">
								{/* {profilePictureUrl ? (
									<img src={profilePictureUrl} alt="Profile" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
								) : (
								)} */}
								<AccountCircleIcon style={{ width: 42, height: 42, color: '#E5E7EB' }} />
								{/* <img alt="Tailwind CSS Navbar component" src="https://daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg" /> */}
							</div>
						</div>
						<ul tabIndex={0} className="menu dropdown-content menu-sm z-[1] mt-3 w-52 rounded-box bg-gray-800 p-2 shadow">
							{token ? (
								<div>
									<li>
										<a href={'/user'} style={{ color: '#E5E7EB' }}>Profile</a>
									</li>
									<li>
										<a href="/settings" style={{ color: '#E5E7EB' }}>Settings</a>
									</li>
									<li>
										<Logout />
									</li>
								</div>
							) : (
								<div>
									<li>
										<a href="login" style={{ color: '#E5E7EB' }}>Login</a>
									</li>
								</div>
							)}
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}
