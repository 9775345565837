import React from 'react'

interface ModalProps {
	message: string
	icon: React.ReactNode
	onClose: () => void
}

const Modal_error: React.FC<ModalProps> = ({ message, icon, onClose }) => (
	<div className="modal-overlay">
		<div className="modal">
			<div className="modal-content">
				<p>{message}</p>
				<div className="icon">{icon}</div>
				<button onClick={onClose}>Schließen</button>
			</div>
		</div>
	</div>
)
export default Modal_error
