import { useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"

function Message(props: { message: string; isError: boolean }) {

	useEffect(() => {
		if(props.isError) {
			toast.error(props.message, {
				position: toast.POSITION.BOTTOM_CENTER,
				// autoClose: 1000,
			})
		} else {
			toast.success(props.message, {
				position: toast.POSITION.BOTTOM_CENTER,
				autoClose: 1000,
			})
		}
	}, [])

	return (
		<ToastContainer/>
	)
}

export default Message
