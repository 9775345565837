import React, { useState } from 'react'
import Message from '../Message'
// import '../styles/registrationDialogue.css'

interface ModalProps {
	message: string
	icon: React.ReactNode
	onClose: () => void
}

const Modal: React.FC<ModalProps> = ({ message, icon, onClose }) => (
	<div className="modal-overlay">
		<div className="modal">
			<div className="modal-content">
				<p>{message}</p>
				<div className="icon">{icon}</div>
				<button onClick={onClose}>Schließen</button>
			</div>
		</div>
	</div>
)

const RegistrationForm: React.FC = () => {
	const [username, setUsername] = useState<string>('')
	const [email, setEmail] = useState<string>('')
	const [password, setPassword] = useState<string>('')

	const [loading, setLoading] = useState<boolean>(false)
	const [success, setSuccess] = useState<boolean>(false)
	const [error, setError] = useState<string>('')
	const [isLoggedIn, setIsLoggedIn] = useState(false)

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault()
		setLoading(true)
		try {
			const response = await fetch(import.meta.env.VITE_API_URL + 'auth/register', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					username: username,
					email: email,
					password: password,
				}),
			})
			// console.log(response.status)
			if (response.status === 201) {
				const data = await response.json()
				const token = data.access_token
				localStorage.setItem('token', token)
				// Funktion zum Extrahieren der Benutzer-ID aus dem JWT-Payload
				// Getestet ob userid im backend selbe userid wie im frontend
				// const extractUserIdFromToken = (token: string | undefined) => {
				// 	try {
				// 		if (!token) {
				// 			console.error('Token ist undefined oder null.')
				// 			return null
				// 		}

				// 		const payloadBase64 = token.split('.')[1]
				// 		const payload = JSON.parse(atob(payloadBase64))
				// 		return payload.sub || null // Annahme: Die Benutzer-ID ist im Feld "sub"
				// 	} catch (error) {
				// 		console.error('Fehler beim Extrahieren der Benutzer-ID:', error)
				// 		return null
				// 	}
				// }

				// Benutzer-ID extrahieren und in der Konsole ausgeben
				// const userId = extractUserIdFromToken(token)
				// console.log(' FRONTEND Benutzer-ID:', userId)
				////
				setSuccess(true)
				setTimeout(() => {
					window.location.href = '/'
				}, 2000)
			} else {
				const data = await response.json()
				if (data.error === 'email_already_exists') {
					setError('Diese E-Mail-Adresse wird bereits verwendet.')
				} else {
					setError(`Fehler bei der Registrierung: Diese E-Mail wird bereits verwendet.`)
				}
			}
		} catch (error) {
			console.error('Fehler bei der Registrierung', error)
			setError('Registrierung fehlgeschlagen')
		} finally {
			setLoading(false)
		}
	}

	const resetForm = () => {
		setUsername('')
		setEmail('')
		setPassword('')
		setSuccess(false)
		setError('')
	}

	return (
		<div>
			{loading}

			{!loading && (
				<form className="flex flex-col" onSubmit={handleSubmit}>
					<input className="sm:text-md mb-5 block rounded-lg border border-gray-900 bg-gray-800 p-4 text-white" type="text" name="name" id="name" onChange={(e) => setUsername(e.target.value)} required placeholder="Username" />
					<input className="sm:text-md mb-5 block rounded-lg border border-gray-900 bg-gray-800 p-4 text-white" type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)} required placeholder="E-mail" />
					<input className="sm:text-md mb-5 block rounded-lg border border-gray-900 bg-gray-800 p-4 text-white" type="password" name="password" id="password" onChange={(e) => setPassword(e.target.value)} required placeholder="Password" />
					<input className="sm:text-md mb-5 block rounded-lg border border-gray-900 bg-gray-800 p-4 text-white" type="password" name="repPassword" id="repPassword" required placeholder="Repeat password" />
					<button className="mb-5 rounded-lg border-gray-900 bg-gray-900 p-4 text-white hover:text-gray-500" type="submit">
						Register
					</button>
				</form>
			)}

			{success && (
				<Message message='You have successfully logged in!' isError={false} />
			)}

			{error && (
				<Message message={error} isError={true} />
			)}
		</div>
	)
}

export default RegistrationForm
