import React from 'react'
import '@styles/verification.css'

const VerificationSuccess = () => {
	setTimeout(() => {
		window.location.href = '/'
	}, 1500)

	return (
		<div className="flex h-[calc(100vh-68px)] w-screen flex-col items-center justify-center">
			<h1 className="text-4xl font-bold text-white">Verifizierung erfolgreich</h1>
		</div>
	)
}

export default VerificationSuccess
