import React from 'react'
import CloseIcon from '@mui/icons-material/Close'

function HelpModal(props: { isVisible: React.Dispatch<React.SetStateAction<boolean>> }) {
	return (
		<div className="flex h-[calc(100vh-68px)] w-screen items-center justify-center overflow-hidden bg-transparent backdrop-blur-md" onClick={() => props.isVisible(false)}>
			<div className="flex flex-col gap-4 rounded-2xl bg-gray-800">
				<div className="mx-auto mt-4 flex w-11/12 justify-between border-b-2 pb-6 text-2xl text-gray-200">
					<h1 className="text-gray-200">Help</h1>
					<button onClick={() => props.isVisible(false)}>
						<CloseIcon />
					</button>
				</div>
				<div className="mx-auto flex w-11/12 justify-between" style={{ color: '#b8b8b8' }}>
					Start typing to improve your typing skills.
					<br />
					You can change the language in the menu above. You can also change the current mode in the modi bar (top right).
				</div>
				<div className="mx-auto mb-4 flex w-11/12 flex-col justify-between">
					<p className="flex" style={{ color: '#b8b8b8' }}>
						<span className="text-green-400"  >sampletext</span>:&emsp;last typed character is correct
					</p>
					<p className="flex" style={{ color: '#b8b8b8' }}>
						<span className="underline">sampletext</span>:&emsp;last typed character was incorrect
					</p>
					<p className="flex" style={{ color: '#b8b8b8' }}>
						<span className="text-red-400">sampletext</span>:&emsp;typed word is too long
					</p>
				</div>

			</div>
		</div>
	)
}

export default HelpModal

