import React, { useState, useEffect } from 'react'
import fetchWithToken from './login/fetchWithToken'
type FriendResult = {
	wpm: number
	accuracy: number
	words_amount: number
	language: string
	created_at: string // oder Date, abhängig von Ihrer API-Antwort
	time: number
}

type FriendResultsComponentProps = {
	friendId: number
	token: string
}

const FriendResultsComponent: React.FC<FriendResultsComponentProps> = ({ friendId, token }) => {
	const [results, setResults] = useState<FriendResult[]>([])

	useEffect(() => {
		const fetchFriendResults = async () => {
			try {
				const response = await fetchWithToken(import.meta.env.VITE_API_URL + `friends/friendResults/${friendId}`, {
					headers: { Authorization: `Bearer ${token}` },
				})
				if (!response.ok) {
					throw new Error('Failed to fetch friend results')
				}
				const data = await response.json()
				setResults(data)
			} catch (error) {
				console.error('Error fetching friend results:', error)
			}
		}

		fetchFriendResults()
	}, [friendId, token])

	return (
		<div>
			{results.map((result, index) => (
				<div key={index} className="mt-4 flex w-full justify-around">
					<p className="pr-1">WPM: {result.wpm}</p>
					<p className="pr-1">Genauigkeit: {result.accuracy}%</p>
					<p className="pr-1">Wörteranzahl: {result.words_amount}</p>
					<p className="pr-1">Time: {result.time}</p>
					<p className="pr-1">Sprache: {result.language}</p>
					<p className="pr-1">Erstellt am: {new Date(result.created_at).toLocaleDateString()}</p>
				</div>
			))}
		</div>
	)
}

export default FriendResultsComponent
